/* eslint-disable */
import { PureComponent } from 'react';
import { Field as SourceField }
    from 'SourceComponent/Field/Field.component';
import FieldInput from 'Component/FieldInput';

// import new extended styles file
import './Field.override.style.scss';

/**
 * Input fields component
 * @class Field
 * @namespace Component/Field/Component
 */
export class Field extends SourceField {
    renderRadioButton() {
        const {
            id,
            label,
            onClick,
            onChange,
            checked,
            image = null,
            recommended = null
        } = this.props;
        // console.log(`ID: ${id}, Checked: ${checked}`);
        // console.log(this.props);

        return (
            <div
                block="Field"
                elem="FieldContentContainer"
            >
                <FieldInput
                    {...this.props}
                    type="radio"
                    onClick={ onClick }
                />
                { image }
                { recommended }
                <label htmlFor={ id } />
            </div>
        )
    }

    renderCheckbox() {
        const {
            id,
            onChangeCheckbox,
            image = null,
            recommended = null
        } = this.props;

        return (
            <div
                block="Field"
                elem="FieldContentContainer"
            >
                <FieldInput
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                />
                { image }
                { recommended }
                <label htmlFor={ id } />
            </div>
        );
    }
}

export default Field;